@import "./1_variables";
@import "./_0_mediaquery";

.data {
  height: 100vh;
  overflow-y: auto;
}

.dcontainer {
  max-width: 85%;
  width: 100%;
  margin: auto;
}

.arc {
  padding: 1em;
  width: 100%;

  .slist {
    min-height: 70vh;
    height: fit-content;
    width: 100%;

    h1 {
      color: $secondary;
      font-size: xx-large;
      margin: 10px 0 10px 0;
      padding: 0 0 44px 0;
      position: relative;
      text-align: left !important;
      width: fit-content;
      max-width: 85%;
      margin: 1em auto 0 auto;
      display: block;
    }

    h1::before {
      content: "";
      position: absolute;
      left: 7%;
      bottom: 16px;
      width: 150px;
      height: 14px;
      transform: skew(-12deg) translateX(-50%);
      background: #ecc115;
      z-index: 2;
    }

    .scontainer {
      width: 100%;
      height: 100%;
      margin: auto;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 2em;
      padding: 0 0 2em 0;

      .left {
        border: 1px solid #60606030;
        border-radius: 5px;
        height: fit-content;
        margin-top: 54px;
        transition: position 0.3s ease-in-out;

        h3 {
          padding: 4px;
          color: $primary;
        }

        hr {
          background-color: gray !important;
        }

        .category {
          border-bottom: 1px solid #60606030;
          cursor: pointer;

          p {
            color: gray;
            padding: 5px 20px;
          }
        }

        .category:hover {
          background-color: #60606030;
        }
      }

      .fixed {
        position: fixed !important;
        top: 10vh;
      }

      .smain {
        padding: 5px;
        height: 100%;
        width: 100% !important;


        .stop {
          display: grid;
          grid-template-columns: 1fr auto auto;
          gap: 10px;
          margin-bottom: 10px;
          width: 100%;

          .yr {
            display: grid;
            grid-template-columns: auto auto;
            gap: 10px;
            width: fit-content;
            height: fit-content;

            h4 {
              padding: 0px !important;
              display: block;
              margin: auto;
              height: fit-content;
              font-weight: 400;
              font-size: small;
            }

            select {
              height: fit-content;
              padding: 5px;
              border-bottom: 1px solid orange;
              border-top: none;
              border-left: none;
              border-right: none;
              color: white;
              outline: none;
              background-color: $accent;
              cursor: pointer;
              margin: auto;
            }
          }

          form {
            display: grid;
            grid-template-columns: 1fr auto;
          }

          p {
            padding: 10px;
            margin: auto;
            width: 100%;
          }

          input {
            height: fit-content;
            padding: 5px;
            border-bottom: 1px solid orange;
            border-top: none;
            border-left: none;
            color: white;
            border-right: none;
            outline: none;
            background-color: $accent;
            cursor: pointer;
            margin: auto;
          }

          i {
            height: fit-content;
            padding: 5px;
            background-color: transparent;
            color: white;
            text-align: center;
            border-radius: 5px;
            cursor: pointer;
            margin-left: -28px;
          }
        }

        hr {
          color: #60606030 !important;
        }

        .mlist {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 2em;
          width: 100%;
        }

        .stk {
          position: relative;
          cursor: pointer;

          .edit {
            position: absolute;
            top: 10px;
            right: 10px;
            z-index: 99;
            display: grid;
            grid-template-columns: repeat(4, auto);
            gap: 6px;

            .it {
              background-color: white;
              cursor: pointer;
              padding: 5px;
              border-radius: 5px;
              color: $secondary;
            }

            .it:hover {
              background-color: orangered;
            }
          }

          img {
            width: 100%;
            max-height: 100%;
            height: 300px;
            border-radius: 10px;
            object-fit: cover;
            object-position: top;
            border: 1px solid #60606030;
            box-shadow: 2px 2px 5px #60606050;
          }

          .tp {
            h3 {
              color: $secondary;
              display: block;
              margin: auto;
              width: 100%;
            }
          }

          .np {
            margin: -3em 1em 0 -1em;
            position: relative;
            background: linear-gradient(-60deg, #fdeedc 10%, $bg1);
            border-radius: 5px;
            padding: 1em;
            border: 1px solid #60606030;
            box-shadow: 1px 1px 5px #60606030;
            transition: transform 0.3s ease-in-out;

            h3 {
              color: black;
              display: block;
              margin: auto;
              width: 100%;
              font-weight: 500;
              font-size: small;
              overflow: hidden;
              line-height: 16px;
              height: 32px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            h4 {
              margin: 5px 0 5px 0;
              font-weight: 500;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              line-clamp: 1;
              -webkit-box-orient: vertical;
              color: $primary;
              font-size: x-small;
            }

            p {
              font-size: x-small;
              margin: 3px 0 3px 0;
              overflow: hidden;
              line-height: 14px;
              height: 56px;
              display: -webkit-box;
              -webkit-line-clamp: 4;
              line-clamp: 4;
              -webkit-box-orient: vertical;
            }

            a {
              display: block;
              margin-left: auto;
              width: fit-content;
              cursor: pointer;
              text-decoration: none;
              font-size: small;
              color: blue;

              i {
                color: blue;
              }
            }

            a:hover {
              color: blueviolet !important;

              i {
                color: blueviolet !important;
              }
            }
          }

          p {
            line-height: 1.7;
            color: gray;
            font-size: small;
            margin-bottom: 10px;
          }

          a {
            display: block;
            margin-left: auto;
            width: fit-content;
            cursor: pointer;
            font-size: small;
            color: $secondary;
            text-decoration: none;

            i {
              color: $secondary;
            }
          }

          a:hover {
            i {
              color: $primary !important;
            }
          }
        }

        .stk:hover {
          .np {
            transform: translateX(1em);
          }
        }
      }
    }

    @include maxwidth(mobile) {
      .scontainer {
        grid-template-columns: 1fr !important;

        .left {
          display: none;
        }

        .top {
          grid-template-columns: 1fr !important;

          p {
            display: none;
          }

          input {
            width: 100% !important;
          }
        }

        .list {
          grid-template-columns: 1fr !important;
        }
      }
    }

    @include maxwidth(tablet) {
      .scontainer {
        grid-template-columns: 1fr !important;

        .left {
          display: none;
        }

        .top {
          grid-template-columns: 1fr !important;

          p {
            display: none;
          }

          input {
            width: 100% !important;
          }
        }

        .list {
          grid-template-columns: 1fr !important;
        }
      }
    }
  }
}